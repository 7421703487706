<template>
  
  <router-view></router-view>
  <notifications width="400" position="top center" classes="my-notification" />
  <FeedbackComponent/>
  <n-modal-provider>
  <AgeVerificationComponent/>
  </n-modal-provider>
  <n-notification-provider :placement="'bottom-left'">
    <CookieNotificationComponent />
  </n-notification-provider>
  <ScrollOnTop />
</template>

<script>
import FeedbackComponent from '@/components/FeedbackForm/FeedbackComponent.vue'
import AgeVerificationComponent from '@/components/Widgets/AgeVerificationComponent.vue'
import CookieNotificationComponent from '@/components/Widgets/CookieNotificationComponent.vue'
import ScrollOnTop from '@/components/ScrollOnTOP.vue'
export default {
  components: {
    FeedbackComponent,
    AgeVerificationComponent,
    CookieNotificationComponent,
    ScrollOnTop,
  }
}
</script>

<style>
.my-notification {
  margin: 0 5px 5px;
  margin-top: 50px;
  padding: 10px;
  font-size: 12px;
  border-left: 5px solid #3d3d3d;
}
.notification-title {
  color: #030303;
}

</style>
